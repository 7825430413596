$(() => {
  function removeStretchedLinks() {
    $('.stretched-link').addClass('stretched-link-removed').removeClass('stretched-link');
  }

  function addStretchedLinks() {
    $('.stretched-link-removed').addClass('stretched-link').removeClass('stretched-link-removed');
  }

  function highlightMissingTranslations() {
    $('.translation_missing.localeapp').addClass('highlight');
  }

  function unhighlightMissingTranslations() {
    $('.translation_missing.localeapp.highlight').removeClass('highlight');
  }

  function addLinksToTranslations() {
    $('.translation_missing.localeapp').each(function () {
      const title = $(this).attr('title');
      const matched = title.match(/translation missing: \w{2}-\w{2}\.(.*)$/);
      if (matched) {
        const i18nKey = matched[1];
        const url = `https://app.localeapp.com/projects/11360/translations?search=${i18nKey}`;
        const $link = $('<a>Open translation</a>');
        $link.attr('href', url);
        $link.attr('target', '_blank');
        $link.addClass('small mark open-translation-link');
        $(this).after($link);
      }
    });
  }

  function removeLinksToTranslations() {
    $('.open-translation-link').remove();
  }

  $('a.missing-translations').each(function () {
    const $link = $(this);
    const missingTranslationCount = $('.translation_missing.localeapp').length;

    $link.text(`${$link.text()} (${missingTranslationCount})`);

    $link.click(function (event) {
      event.preventDefault();

      if ($(this).hasClass('hide')) {
        removeLinksToTranslations();
        unhighlightMissingTranslations();
        addStretchedLinks();
      } else {
        removeStretchedLinks();
        highlightMissingTranslations();
        addLinksToTranslations();
      }

      $('a.missing-translations').toggle();
    });
  });
});
