/* eslint no-alert: "off" */
$(() => {
  $('[data-confirm]').each(function confirmationDialog() {
    const message = $(this).attr('data-confirm');
    $(this).click((event) => {
      if (!window.confirm(message)) {
        event.preventDefault();
      }
    });
  });
});
