$(() => {
  $('input[name="exclude"]').click((event) => {
    if (event.target.value === 'true') {
      $('#artformList').prop('disabled', true);
    } else {
      $('#artformList').prop('disabled', false);
    }
  });
});

$(() => {
  function toggleArtformSubmitButton() {
    const excludedValue = $('input[name="exclude"]:checked').val();
    const artformValue = $('select[name="artform"]').val();
    const performanceIdCount = $('input[name="performance_ids[]"]:checked').length;

    const performancesSelected = performanceIdCount > 0;
    const excluded = excludedValue === 'true';
    const included = !excluded;
    const artformSelected = artformValue !== '';

    if (performancesSelected && (excluded || (included && artformSelected))) {
      $('#submit-categorisation').prop('disabled', false);
    } else {
      $('#submit-categorisation').prop('disabled', true);
    }
  }

  $('input[name="exclude"], select[name="artform"], input[name="performance_ids[]"]').change(() => {
    toggleArtformSubmitButton();
  });

  toggleArtformSubmitButton();
});

$(() => {
  function toggleCategorisationForm() {
    const categorisationForm = $('#artformList, #exclude-select, #submit-categorisation');
    const performanceIdCount = $('input[name="performance_ids[]"]:checked').length;
    const performancesSelected = performanceIdCount > 0;

    if (performancesSelected) {
      categorisationForm.css('display', 'block');
    } else {
      categorisationForm.css('display', 'none');
    }
  }
  $('input[name="performance_ids[]"]').change(() => {
    toggleCategorisationForm();
  });
  toggleCategorisationForm();
});
