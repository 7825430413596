function constructColumnChartForReachMetric() {
  const chartSelector = 'reach-chart';
  const el = $(`#${chartSelector}`);

  const series = el.data('series') || [];
  const categories = series.map((c) => c.name);
  const primaryData = series.map((c) => c.primary);
  const comparisonData = series.map((c) => c.comparison);

  const translations = el.data('translations');

  // eslint-disable-next-line no-undef
  Highcharts.chart('reach-chart', {
    chart: {
      type: 'column',
      styledMode: true,
    },
    title: {
      text: translations.chart_title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      format: translations.highcharts_tooltip_html,
    },
    xAxis: {
      categories,
      title: {
        align: 'low',
        text: translations.x_axis_label,
        margin: 20,
      },
    },
    yAxis: {
      title: {
        align: 'high',
        text: translations.y_axis_label,
        margin: 20,
      },
      labels: {
        format: '{text}%',
      },
    },
    series: [{
      name: translations.primary_period_label,
      data: primaryData,
    }, {
      name: translations.comparison_period_label,
      data: comparisonData,
    }],
  });
}

$(document).ready(() => {
  $('*[data-graph-type="location-page-reach-metric"]').each(() => {
    $(document).on('reach-metric-loaded', () => {
      constructColumnChartForReachMetric();
    });
  });
});
