function constructColumnChartForReachMetric() {
  const chartSelector = 'reach-chart';
  const el = $(`#${chartSelector}`);

  const series = el.data('series') || [];
  const categories = series.map((c) => c.name);
  const primaryData = series.map((c) => c.primary);
  const comparisonData = series.map((c) => c.comparison);

  const translations = el.data('translations');

  // Custom labels only if spectrum config is present
  const audienceSpectrumInfo = el.data('as-info');
  let labels = null;
  if (audienceSpectrumInfo) {
    labels = {
      align: 'left',
      reserveSpace: true,
      useHTML: true,
      formatter() {
        return `
                <span class="aa-xlabel" style="width: 143px;">
                  <span class="aa-xlabel-title">${this.value}</span>
                  <span class="aa-xlabel-icon">${audienceSpectrumInfo[this.value].icon}</span>
                </span>
              `;
      },
    };
  }

  // eslint-disable-next-line no-undef
  return Highcharts.chart('reach-chart', {
    chart: {
      type: 'bar',
      styledMode: true,
    },
    title: {
      text: translations.chart_title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      format: translations.highcharts_tooltip_html,
    },
    xAxis: {
      categories,
      ...(labels && { labels }),
      title: {
        align: 'low',
        text: translations.x_axis_label,
        margin: 20,
      },
    },
    yAxis: {
      title: {
        align: 'high',
        text: translations.y_axis_label,
        margin: 20,
      },
      labels: {
        format: '{text}%',
      },
    },
    series: [{
      name: translations.primary_period_label,
      data: primaryData,
    }, {
      name: translations.comparison_period_label,
      data: comparisonData,
    }],
  });
}

function showExtraComparison(reachChart) {
  const chartSelector = 'reach-chart';
  const el = $(`#${chartSelector}`);

  const series = el.data('series-alt');
  const primaryData = series.map((c) => c.primary);
  const comparisonData = series.map((c) => c.comparison);
  const extraData = series.map((c) => c.extra);

  const translations = el.data('translations');

  reachChart.update({
    yAxis: {
      title: {
        text: translations.alt_y_axis_label,
      },
      labels: {
        format: null,
      },
    },
    tooltip: {
      format: translations.alt_highcharts_tooltip_html,
    },
    series: [{
      name: translations.primary_period_label,
      data: primaryData,
    }, {
      name: translations.comparison_period_label,
      data: comparisonData,
    },
    {
      name: translations.alt_total_households_label,
      data: extraData,
    }],
  }, true, true);
}

function hideExtraComparison(reachChart) {
  const chartSelector = 'reach-chart';
  const el = $(`#${chartSelector}`);

  const series = el.data('series');
  const primaryData = series.map((c) => c.primary);
  const comparisonData = series.map((c) => c.comparison);

  const translations = el.data('translations');

  reachChart.update({
    yAxis: {
      title: {
        text: translations.y_axis_label,
      },
      labels: {
        format: '{text}%',
      },
    },
    tooltip: {
      format: translations.highcharts_tooltip_html,
    },
    series:
      [{
        name: translations.primary_period_label,
        data: primaryData,
      }, {
        name: translations.comparison_period_label,
        data: comparisonData,
      }],
  }, true, true);
}

$(document)
  .ready(() => {
    $('*[data-graph-type="spectrum-page-reach-metric"]')
      .each(() => {
        let reachChart = null;
        $(document)
          .on('reach-metric-loaded', () => {
            reachChart = constructColumnChartForReachMetric();
          });
        $('#reach-toggle-bookers')
          .on('click', () => {
            if (reachChart) {
              showExtraComparison(reachChart);
            }
          });
        $('#reach-toggle-percent')
          .on('click', () => {
            if (reachChart) {
              hideExtraComparison(reachChart);
            }
          });
      });
  });
