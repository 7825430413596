/* eslint no-shadow: "off" */
const fetchFragment = function (urlPath, selector, data) {
  $.ajax({
    url: urlPath,
    type: 'GET',
    data,
  }).then((data) => {
    $(selector).html(data);
  });
};
window.fetchFragment = fetchFragment;

const postData = function (endpoint, data, callback) {
  $.ajax({
    type: 'POST',
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    url: endpoint,
    data,
    datatype: 'json',
  }).done(callback);
};

export { fetchFragment, postData };
