$(window).scroll(() => {
  const stickyClasses = 'sticky-top text-white bg-dark ease';
  $('#sticky-heading').each(function () {
    const stickyHeading = $(this);
    const headingOffset = $(window).scrollTop();

    if (headingOffset <= 40) {
      stickyHeading.removeClass(stickyClasses);
    } else {
      stickyHeading.addClass(stickyClasses);
    }
  });
});

$('#sticky-heading').each(function () {
  const wordArray = $(this).text().split(' ');
  if (wordArray.length > 1) {
    wordArray[wordArray.length - 2] += `&nbsp;${wordArray[wordArray.length - 1]}`;
    wordArray.pop();
    $(this).html(wordArray.join(' '));
  }
});
