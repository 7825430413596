import L from 'leaflet';

function zoomLevel(radius) {
  if (radius <= 1) {
    return 14;
  } if (radius > 1 && radius <= 2) {
    return 13;
  } if (radius > 2 && radius <= 4) {
    return 12;
  } if (radius > 4 && radius <= 8) {
    return 11;
  } if (radius > 8 && radius <= 16) {
    return 10;
  } if (radius > 16 && radius <= 32) {
    return 9;
  } if (radius > 32 && radius <= 64) {
    return 8;
  } if (radius > 64 && radius <= 128) {
    return 7;
  } if (radius > 128 && radius <= 256) {
    return 6;
  } if (radius > 256 && radius <= 512) {
    return 5;
  } if (radius > 512 && radius <= 1024) {
    return 4;
  } if (radius > 1024 && radius <= 2048) {
    return 3;
  } if (radius > 2048 && radius <= 4096) {
    return 2;
  }
  return 1;
}

function makeLayers(map, organisationLocation, catchmentCircleOptions) {
  const houseIcon = L.icon({
    iconUrl: '/map-marker/map-marker-house.svg',
    iconSize: [45.12, 57],
    iconAnchor: [22.56, 57],
    shadowUrl: '/map-marker/map-marker-house-shadow.svg',
    shadowSize: [32.44, 45.36],
    shadowAnchor: [0, 45.36],
  });

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(map);

  L.marker(organisationLocation, { icon: houseIcon }).addTo(map);
  L.circle(organisationLocation, catchmentCircleOptions).addTo(map);
}

function catchmentArea(mapSelector, background) {
  const el = $(`#${mapSelector}`);
  const radius = el.data('radius');
  const longitude = el.data('longitude');
  const latitude = el.data('latitude');
  const maxRadius = el.data('maxRadius') || radius;

  const organisationLocation = [latitude, longitude];
  const map = L.map(mapSelector, {
    scrollWheelZoom: false,
  }).setView(organisationLocation, zoomLevel(maxRadius / 1000));

  const catchmentCircleOptions = {
    radius,
    stroke: false,
    fill: true,
    fillColor: background,
    fillOpacity: 0.6,
  };

  makeLayers(map, organisationLocation, catchmentCircleOptions);
}

$(document).ready(() => {
  document.addEventListener('primary-catchment-area-loaded', () => {
    catchmentArea('primary-catchment-area-map', '#2DB8C5');
  });

  document.addEventListener('comparison-catchment-area-loaded', () => {
    catchmentArea('comparison-catchment-area-map', '#1D7981');
  });

  document.addEventListener('pre-covid-reference-catchment-area-loaded', () => {
    catchmentArea('pre-covid-reference-catchment-area-map', '#636AAF');
  });
});

window.catchmentArea = catchmentArea;
