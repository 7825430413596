/* eslint class-methods-use-this: "off" */

import moment from 'moment';
import { fetchFragment, postData } from './load_by_ajax';

// The table for the performance categorisation powered by the DataTables.js library
class ProductionCategorisationTable {
  constructor() {
    const self = this;

    // Labels will be updated from the API
    self.labels = {};

    // Putting table columns here so we can quickly
    // look up indexes rather than storing in magic numbers
    self.tableColumns = [
      {
        className: 'select-checkbox',
        orderable: false,
        render: () => '',
      },
      { data: 'name' },
      { data: 'group_name' },
      { data: 'venue' },
      {
        data: 'opening_date',
        type: 'date',
        render: (data) => moment(data).format('D MMM YYYY'),
      },
      { data: 'count' },
      {
        data: 'artform',
        render: (data, type, row) => {
          let label = self.labels.uncategorised;
          if (row.excluded === true) {
            label = self.labels.excluded;
          } else if (row.artform) {
            label = row.artform;
          }
          return `<div class="fancy-select">
                            <button class="select-selected w-100 text-left" aria-expanded="false" tabindex="0" data-perf-ids="${row.perf_ids}">
                                ${label}
                            </button>
                            <div data-perf-ids-dropdown="${row.perf_ids}"></div>
                        </div>`;
        },
      },
    ];

    self.table = $('#production-categorisation').DataTable({
      ajax: {
        url: 'production-categorisation/productions-data',
        data: (d) => {
          const options = d;
          // Filter to the active tab, the filters are set on data attributes within the HTML
          options.filter = $('#production-categorisation-tabs .nav-link.active').parent().attr('data-filter');
        },
        dataSrc: (json) => {
          // Update the labels from the API
          self.labels = json.labels;
          const { productions } = json;
          return productions;
        },
      },
      // Column definitions for the tables
      columns: self.tableColumns,
      // This adds necessary html around the datatables elements
      dom: '<"#advanced.border shadow-sm p-3 collapse smallish mt-2" Q><"#table-container" t><"mb-3 font-italic smallish" lip>',
      // Allow columns to be selected (this triggers the modal for multi-categorisation)
      select: {
        style: 'multi',
        selector: 'td:not(:last-child)',
      },
      createdRow: (row, data) => {
        if (data.excluded) {
          $(row).addClass('excluded');
        } else if (data.artform) {
          $(row).addClass('categorised');
        }
      },
      deferRender: true,
      order: [[self.getColumnIndexByDataKey('opening_date'), 'desc']], // default sort order on load (start date descending)
      paging: true,
      language: {
        searchBuilder: {
          title: 'Advanced Search Builder', // Changes the title of the Advanced Search Builder
        },
      },
      // specifies which columns to include in the Advanced Search Builder
      searchBuilder: {
        columns: [self.getColumnIndexByDataKey('name'), self.getColumnIndexByDataKey('group_name'), self.getColumnIndexByDataKey('venue'), self.getColumnIndexByDataKey('opening_date'), self.getColumnIndexByDataKey('count')],

      },

    }).on('select deselect', () => {
      // Handle different select all button states

      const currentPageSelectedRows = self.table.rows({ selected: true, page: 'current' });
      const currentPageRows = self.table.rows({ page: 'current' });

      if (!currentPageSelectedRows.count()) {
        $('.selectAll').prop('checked', false);
        $('.selectAll').parent().attr('data-selected-state', 'none');
      } else if (currentPageSelectedRows.count() === currentPageRows.count()) {
        $('.selectAll').prop('checked', true);
        $('.selectAll').parent().attr('data-selected-state', 'all');
      } else {
        $('.selectAll').prop('checked', false);
        $('.selectAll').parent().attr('data-selected-state', 'some');
      }

      const allSelectedRows = self.table.rows({ selected: true });

      const perfIDs = allSelectedRows.data().pluck('perf_ids').toArray().flat();

      if (allSelectedRows.count() < 2) {
        self.hideSelectionModal();
        return;
      }

      // Get selected rows

      self.selectionModal(perfIDs, allSelectedRows.indexes().toArray());
    }).on('change', '.selectAll', (e) => {
      // Select all / deselect all
      if (e.target.checked) {
        self.table.rows({ page: 'current' }).select();
      } else {
        self.table.rows().deselect();
      }
    })
      .on('page order', () => {
        self.table.rows().deselect();
      });

    self.registerInteractions();
    self.addDateFilter();
  }

  // Quick lookup of column index by data key
  getColumnIndexByDataKey(dataKey) {
    const self = this;

    return self.tableColumns.findIndex((x) => x.data === dataKey);
  }

  selectionModal(perfIDs) {
    const self = this;

    // Get width of last column
    const artformColumn = self.getColumnIndexByDataKey('artform');

    const artformColum = $('#production-categorisation th').eq(artformColumn);

    const multiWidth = artformColum[0].offsetWidth;

    const content = `<div class="fancy-select py-3">
            <p class="pr-5">Choose an artform for selected production(s)</p>
            <button class="select-selected w-100 text-left fs-1" aria-expanded="false" tabindex="0" data-perf-ids="${perfIDs}"> Choose...</button>
            <div data-perf-ids-dropdown="${perfIDs}"></div>
            </div>`;

    $('#multi_select_modal').remove();

    const multiSelect = document.createElement('div');
    multiSelect.id = 'multi_select_modal';
    multiSelect.style.width = `${multiWidth}px`;
    multiSelect.innerHTML = content;
    multiSelect.style.position = 'absolute';
    multiSelect.style.top = 0;

    $('#prod-cat-table-container').append(multiSelect);
  }

  hideSelectionModal() {
    $('#multi_select_modal').remove();
  }

  registerInteractions() {
    const self = this;

    $(document).on('click', '.select-selected', (e) => {
      self.toggleArtformDropdown($(e.currentTarget));
    });

    $(document).on('keyup', '#artform-search', (e) => {
      const searchTerm = $(e.currentTarget).val().toLowerCase();
      self.filterArtformDropdown(searchTerm);
    });

    $('#production-categorisation-datepicker').on('change', () => {
      self.table.ajax.reload();
    });

    $('#search-table').on('keyup', (e) => {
      self.table.search(e.currentTarget.value).draw();
    });

    $('.nav-link').on('click', (e) => {
      $(e.currentTarget).addClass('active');
      $(e.currentTarget).parent().siblings().children('.nav-link')
        .removeClass('active');
      self.table.ajax.reload();
    });

    $(document).on('click', '.second-tier', (e) => {
      self.categorisePerformances(e.currentTarget);
    });

    $(document).on('click', '#exclude', (e) => {
      self.excludePerformances(e.currentTarget);
    });
  }

  // Add date filter to table search
  addDateFilter() {
    const self = this;

    $.fn.dataTable.ext.search.push(
      (settings, data) => {
        const min = new Date($('#production-categorisation-datepicker #min').val()).setUTCHours(0, 0, 0, 0);
        const max = new Date($('#production-categorisation-datepicker #max').val()).setUTCHours(23, 59, 59, 999);
        const startDate = new Date(data[self.getColumnIndexByDataKey('opening_date')]);

        return (min === null && max === null)
                    || (min === null && startDate <= max)
                    || (min <= startDate && max === null)
                    || (min <= startDate && startDate <= max);
      },
    );
  }

  filterArtformDropdown(searchTerm) {
    const firstTierButtons = $('.select-items button.first-tier');
    const matchesSearch = (button) => button.text().toLowerCase().includes(searchTerm);
    const expandSection = (section) => {
      section.show();
      section.attr('aria-expanded', 'true');
      section.siblings('ul').addClass('show');
    };
    const collapseSection = (section) => {
      section.attr('aria-expanded', 'false');
      section.siblings('ul').removeClass('show');
      section.hide();
    };

    if (!searchTerm) {
      firstTierButtons.each(() => {
        collapseSection($(this));
      });
      return;
    }

    firstTierButtons.each(() => {
      const secondTierButtons = $(this).siblings('ul').children('li').children('button.second-tier');
      // If there is a match on the first tier, show first tier and all second tier
      if (matchesSearch($(this))) {
        expandSection($(this));
        secondTierButtons.each(() => {
          $(this).show();
        });
      } else {
        // If there is a match on the second tier
        // show first tier and just the matching second tiers
        let anySecondTierMatch = false;
        secondTierButtons.each(() => {
          if (matchesSearch($(this))) {
            $(this).show();
            anySecondTierMatch = true;
          } else {
            $(this).hide();
          }
        });
        if (anySecondTierMatch) {
          expandSection($(this));
        } else {
          collapseSection($(this));
        }
      }
    });
  }

  toggleArtformDropdown(elem) {
    const self = this;

    const perfIDs = elem.attr('data-perf-ids');

    const isExpanded = elem.attr('aria-expanded') === 'true';

    if (isExpanded) {
      self.minimiseDropdown(elem);
    } else {
      // First minimise all dropdowns
      self.minimiseAllDropdowns();

      elem.attr('aria-expanded', 'true');
      fetchFragment(
        'production-categorisation/artforms-dropdown',
        `[data-perf-ids-dropdown='${perfIDs}']`,
        { current_path: window.location.pathname },
      );
    }

    elem.toggleClass('select-arrow-active');
  }

  minimiseDropdown(elem) {
    elem.attr('aria-expanded', 'false');
    elem.siblings('div').children('.select-items').remove();
  }

  minimiseAllDropdowns() {
    const self = this;
    $.each($('.select-selected[aria-expanded="true"]'), (index, element) => {
      self.minimiseDropdown($(element));
    });
  }

  categorisePerformances(element) {
    const self = this;

    $('#categorisation-success-message').show();

    // Set count of productions categorised when the single row interface
    // (and not row selection) is used
    let successCount = 1;

    if (self.table.rows({ selected: true }).count()) {
      successCount = self.table.rows({ selected: true }).count();
    }

    $('#success-count').html(successCount);

    const {
      displayBox, rows, label, perfIds,
    } = self.getDropdownContext($(element));

    const artformId = $(element).attr('value');
    const callback = function (resp) {
      if (resp.ok) {
        rows.each((index, elem) => {
          $(elem).attr('class', 'categorised');

          // If multiple then we need to also update the label on all the buttons

          $(elem).find('.select-selected').text(label);
        });
      }
      self.toggleArtformDropdown(displayBox);
    };

    $('#success-category').html(label);

    self.hideSelectionModal();

    postData(
      'production-categorisation',
      {
        artform_id: artformId ? parseInt(artformId) : null,
        perf_ids: perfIds.split(','),
      },
      callback,
    );

    self.table.rows().deselect();
  }

  getDropdownContext(dropdownItem) {
    const self = this;
    let selectedRowElements;

    const displayBox = dropdownItem.closest('.fancy-select').children('.select-selected');

    // Check if we have multiple rows selected

    const selected = $(self.table.containers()[0]).find('tbody tr.selected');

    if (selected.length) {
      selectedRowElements = selected;
    } else {
      selectedRowElements = displayBox.closest('tr');
    }

    return {
      displayBox,
      rows: selectedRowElements,
      label: dropdownItem.text(),
      // NB: The performance ids for each row are stored
      // as a data attribute on the dropdown display box
      perfIds: displayBox.attr('data-perf-ids'),
    };
  }

  excludePerformances(element) {
    const self = this;

    $('#exclusion-success-message').show();

    // Set count of productions categorised when the single row interface
    // (and not row selection) is used
    let exSuccessCount = 1;

    if (self.table.rows({ selected: true }).count()) {
      exSuccessCount = self.table.rows({ selected: true }).count();
    }

    $('#exclusion-success-count').html(exSuccessCount);

    const {
      displayBox, label, rows, perfIds,
    } = self.getDropdownContext($(element));
    const callback = function (resp) {
      if (resp.ok) {
        // displayBox.text(label);

        rows.each((index, elem) => {
          $(elem).attr('class', 'excluded');

          // If multiple then we need to also update the label on all the buttons
          $(elem).find('.select-selected').text(label);
        });
      }
      self.toggleArtformDropdown(displayBox);
    };

    self.hideSelectionModal();

    postData(
      'production-categorisation',
      {
        is_exclusion: true,
        perf_ids: perfIds.split(','),
      },
      callback,
    );

    self.table.rows().deselect();
  }
}

// Sets up the production categorisation table as a DataTable
// See https://datatables.net/ for info
$(() => {
  window.productionCategorisationTable = new ProductionCategorisationTable();
});

// TODO: This should be in a separate helper class
function toggleFullscreen() {
  const elem = document.getElementById('active');
  const openbutton = document.getElementById('fullscreen');
  const closebutton = document.getElementById('close_fullscreen');

  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }

  $(openbutton).toggle();
  $(closebutton).toggle();
}

window.toggleFullscreen = toggleFullscreen;
