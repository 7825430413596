// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const imagePath = (name) => images(name, true)
import $ from 'expose-loader?exposes=$,jQuery!jquery'; // eslint-disable-line
import 'bootstrap';
import 'bootstrap-datepicker';
import 'datatables.net-bs4';
import 'datatables.net-datetime';
import 'datatables.net-responsive-bs4';
import 'datatables.net-searchbuilder-bs4';
import 'datatables.net-select-bs4';
import '@hotwired/turbo-rails';

import 'src/catchment_area';
import 'src/confirmation_dialog';
import 'src/copy_to_clipboard';
import 'src/date_range_picker';
import 'src/date_range_picker_event_group';
import 'src/debug_translations';
import 'src/duca_cookie_button';
import 'src/double_date_range_filter';
import 'src/highcharts';
import 'src/reports/highcharts_segment_reach_bar_chart';
import 'src/reports/highcharts_reach_column_chart';
import 'src/reports/highcharts_two_series_bar';
import 'src/load_by_ajax';
import 'src/organisation_selector';
import 'src/overview_dashboard_segments_catchment_chart';
import 'src/performance_categorisation';
import 'src/production_categorisation';
import 'src/sticky_heading';
import 'src/survey_selector';
import 'src/ticketing_feed_selector';
import 'src/tooltip';
import 'src/turbo_animations';

const images = require.context('./images', true); // eslint-disable-line
