const surveyReportsStyling = {
  chart: {
    type: 'bar',
    styledMode: true,
  },
  legend: { enabled: false },
  title: false,
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderColor: '#1F8089',
      color: {
        pattern: {
          path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 5,
          },
          width: 10,
          height: 10,
          opacity: 1,
          color: '#1F8089',
        },
      },
    },
  },
  exporting: {
    chartOptions: {
      title: {
        text: null,
      },
    },
    buttons: {
      contextButton: {
        menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG', 'separator', 'downloadCSV'],
      },
    },
  },
};

$(document).ready(() => {
  $('*[data-graph-type="survey-reports"]').each(function () {
    // convert from strings to arrays
    const labels = JSON.parse($(this).attr('data-labels'));
    const counts = JSON.parse($(this).attr('data-counts'));

    const xAxis = {
      xAxis: {
        categories: labels,
        title: false,
      },
    };

    const yAxis = {
      yAxis: {
        title: {
          align: 'low',
          text: $(this).attr('data-series-name'),
          margin: 20,
        },
      },
    };

    const seriesData = {
      series: [{
        name: $(this).attr('data-series-name'),
        data: counts,
      }],
    };

    // eslint-disable-next-line no-undef
    Highcharts.chart($(this).attr('id'), {
      ...surveyReportsStyling,
      ...xAxis,
      ...yAxis,
      ...seriesData,
    });
  });
});
