import DateConstants from './date_constants';

function setDatePickerOptions(selector, startDate, endDate) {
  const datePickerOptions = {
    autoclose: true,
    clearBtn: true,
    endDate: endDate ? new Date(endDate) : new Date(),
    format: 'D, dd M yyyy',
    language: 'en-GB',
    maxViewMode: 2,
    todayBtn: true,
    todayHighlight: true,
  };

  datePickerOptions.startDate = new Date(startDate);
  $(selector).datepicker(datePickerOptions);
}

$(() => {
  setDatePickerOptions('#headline-datepicker .input-daterange', DateConstants.earliestPerformanceDateWithTransactions);
  setDatePickerOptions('.date-range-picker .input-daterange', DateConstants.earliestPerformanceDateWithTransactions);
  setDatePickerOptions('#production-categorisation-datepicker .input-daterange', DateConstants.earliestPerformanceDateForCategorisation);
  setDatePickerOptions('#insight-datepicker .input-daterange', DateConstants.earliestPerformanceDateWithTransactions);
  const rangedDatepicker = $('#ranged-datepicker');
  const earliestVisitDate = rangedDatepicker.data('start-date');
  const latestVisitDate = rangedDatepicker.data('end-date');
  setDatePickerOptions('#ranged-datepicker .input-daterange', earliestVisitDate, latestVisitDate);
});
