import moment from 'moment';

function jQueryObj(element) {
  return $(`#${element.id}`);
}

function updateDate(dateElement, newDate) {
  // eslint-disable-next-line no-param-reassign
  dateElement.value = newDate.format('ddd, DD MMM YYYY');
  jQueryObj(dateElement).datepicker('update');
}

function updateOnDateChanged(
  currentStartDateElement,
  currentEndDateElement,
  previousStartDateElement,
  previousEndDateElement,
  autoPreviousCheckboxElement,
) {
  const primaryEventGroupRadioButton = document.getElementById('primary_data_selection_event_group');
  const currentStartDate = moment(currentStartDateElement.value);
  const currentEndDate = moment(currentEndDateElement.value);
  const previousStartDate = moment(previousStartDateElement.value);
  const currentDaysDiff = currentEndDate.diff(currentStartDate, 'days');
  const maxPreviousStartDate = moment(currentStartDateElement.value).subtract(currentDaysDiff + 1, 'days');

  if (primaryEventGroupRadioButton && primaryEventGroupRadioButton.checked) {
    jQueryObj(previousEndDateElement).datepicker('setStartDate', new Date(previousStartDate));
    return;
  }

  jQueryObj(previousStartDateElement).datepicker('setEndDate', new Date(maxPreviousStartDate));

  if (autoPreviousCheckboxElement.checked === true) {
    const newPreviousStartDate = currentStartDate.subtract(1, 'year');
    updateDate(previousStartDateElement, newPreviousStartDate);
    updateDate(previousEndDateElement, newPreviousStartDate.add(currentDaysDiff, 'days'));
  } else {
    const newPreviousEndDate = previousStartDate.add(currentDaysDiff, 'days');
    updateDate(previousEndDateElement, newPreviousEndDate);
  }
}

function updatePreviousStartDateDisabledAttr(previousStartDateField, autoPreviousCheckbox) {
  const dateField = previousStartDateField;
  dateField.disabled = autoPreviousCheckbox.checked === true;
}

document.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('current_start_date')) {
    return;
  }

  const currentStartDateField = document.getElementById('current_start_date');
  const currentEndDateField = document.getElementById('current_end_date');
  const previousStartDateField = document.getElementById('previous_start_date');
  const previousEndDateField = document.getElementById('previous_end_date');
  const autoPreviousCheckbox = document.getElementById('auto_previous_period');

  function onDateChanged() {
    updateOnDateChanged(
      currentStartDateField,
      currentEndDateField,
      previousStartDateField,
      previousEndDateField,
      autoPreviousCheckbox,
    );
  }

  currentStartDateField.onchange = onDateChanged;
  currentEndDateField.onchange = onDateChanged;
  previousStartDateField.onchange = onDateChanged;
  autoPreviousCheckbox.onchange = onDateChanged;

  autoPreviousCheckbox.addEventListener('click', () => {
    updatePreviousStartDateDisabledAttr(previousStartDateField, autoPreviousCheckbox);
  });
});

export { updateDate, updateOnDateChanged, updatePreviousStartDateDisabledAttr };
