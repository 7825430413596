// snippet from https://edforshaw.co.uk/hotwire-turbo-stream-animations
document.addEventListener('turbo:before-stream-render', (event) => {
  // Add a class to an element we are about to add to the page
  // as defined by its "data-stream-enter-class"
  if (event.target.firstElementChild instanceof HTMLTemplateElement) {
    const child = event.target.templateContent.firstElementChild;
    const enterAnimationClass = child ? child.dataset.streamEnterClass : null;
    if (enterAnimationClass) {
      event.target.templateElement.content.firstElementChild.classList.add(enterAnimationClass);
    }
  }

  // Add a class to an element we are about to remove from the page
  // as defined by its "data-stream-exit-class"
  const elementToRemove = document.getElementById(event.target.target);
  if (elementToRemove) {
    const { streamExitClass } = elementToRemove.dataset;
    if (streamExitClass) {
      // Intercept the removal of the element
      event.preventDefault();
      const { streamEnterClass } = elementToRemove.dataset;
      if (streamEnterClass) {
        elementToRemove.classList.remove(streamEnterClass);
      }
      elementToRemove.classList.add(streamExitClass);
      // Wait for its animation to end before removing the element
      elementToRemove.addEventListener('animationend', () => {
        event.target.performAction();
      });
    }
  }
});
