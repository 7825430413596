function mapSeriesData(data, typeName, infoMap) {
  const results = [];
  Object.keys(data).forEach((key) => {
    results.push({
      y: data[key].percentage,
      count: data[key].count,
      className: infoMap[key].class,
      type: typeName,
      info: infoMap[key].description,
      pointCat: key,
    });
  });
  return results;
}

function makeTooltip(translations) {
  const tooltip = {
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shared: true,
      useHTML: true,
      className: 'aatooltip',
      outside: true,
      followPointer: false,
      formatter() {
        const point1 = this.points[0].point;
        const point2 = this.points[1].point;
        return `
            <div class="aatooltip-inner">
                <h3 class="aatooltip-title">${this.x}</h3>
                <span class="aatooltip-sub">${translations.your_catchment_area}</span>
                <p class="aatooltip-data"><strong>${point1.count} </strong>${point1.type} (${Math.round(point1.y)}%)</p>
                <p class="aatooltip-data"><strong>${point2.count} </strong>${point2.type} (${Math.round(point2.y)}%)</p>
                <p class="aatooltip-info">${point1.info}</p>
            </div>`;
      },
    },
  };
  return tooltip;
}

function axisWidth() {
  const chartW = $('.catchment').width();
  const barW = $('.catchment .highcharts-plot-background').width();
  $('.aa-xlabel').width(chartW - barW - 65);
}

document.addEventListener('catchment-audience-spectrum-loaded', () => {
  const triggerChart = function () {
    const chartSelector = 'audience-spectrum-bar-chart';
    const el = $(`#${chartSelector}`);

    const bookersSegments = el.data('bookers');
    const householdSegments = el.data('households');
    const infoMap = el.data('segmentInfo');
    const translations = el.data('translations');

    const households = mapSeriesData(householdSegments, translations.households, infoMap);
    const bookers = mapSeriesData(bookersSegments, translations.bookers, infoMap);

    const standardStyling = {
      chart: {
        type: 'bar',
        styledMode: true,
        height: 500,
        events: {
          load() {
            axisWidth();
          },
        },
      },
      plotOptions: {
        series: {
          groupPadding: 0.1,
        },
      },
      legend: { enabled: false },
      title: {
        text: translations.chart_title,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        chartOptions: {
          title: {
            text: translations.chart_title,
            align: 'left',
          },
        },
      },
    };

    const xAxis = {
      xAxis: {
        title: {
          align: 'high',
          text: $(this).attr('data-series-name'),
          margin: 20,
        },
        className: 'aa-labels',
        categories: Object.keys(householdSegments),
        labels: {
          align: 'left',
          reserveSpace: true,
          useHTML: true,
          formatter() {
            return `
              <span>
                <a class="xaxis-link aa-xlabel ${infoMap[this.value].class}" href="${infoMap[this.value].url}" target="_blank" style="width: 143px;">
                  <span class="aa-xlabel-title">${this.value}<span class="learn-more">${translations.learn_more}</span></span>
                  <span class="aa-xlabel-icon">${infoMap[this.value].icon}</span>
                </a>
              </span>
            `;
          },
        },
      },
    };

    const yAxis = {
      yAxis: {
        title: false,
      },
    };

    const seriesData = {
      series: [
        {
          type: 'bar',
          name: translations.percentage_bookers,
          data: bookers,
        },
        {
          type: 'bar',
          name: translations.percentage_households,
          data: households,
        },
      ],
    };

    // eslint-disable-next-line no-undef
    Highcharts.setOptions({
      defs: {
        hatch: {
          tagName: 'pattern',
          id: 'hatch',
          patternUnits: 'userSpaceOnUse',
          width: 4,
          height: 4,
          children: [{
            tagName: 'path',
            d: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
          }],
        },
      },
    });

    // eslint-disable-next-line no-undef
    Highcharts.chart('audience-spectrum-bar-chart', {
      ...standardStyling,
      ...xAxis,
      ...yAxis,
      ...seriesData,
      ...makeTooltip(translations),
    });
  };

  triggerChart();
});

$('.label-bookers').hover(() => {
  $('.highcharts-color-1').css({ opacity: 0.5 });
}, () => {
  $('.highcharts-color-1').css({ opacity: 1 });
});

$('.label-households').hover(() => {
  $('.highcharts-color-0').css({ opacity: 0.5 });
}, () => {
  $('.highcharts-color-0').css({ opacity: 1 });
});
