function constructHighchartsTwoSeriesBarForMetric(metricName) {
  const chartSelector = `${metricName}-chart`;
  const el = $(`#${chartSelector}`);

  const series = el.data('series') || [];
  const categories = series.map((c) => c.name);
  const primaryData = series.map((c) => c.primary);
  const comparisonData = series.map((c) => c.comparison);

  const translations = el.data('translations');

  // Custom labels only if spectrum config is present
  const audienceSpectrumInfo = el.data('as-info');
  let labels = null;
  if (audienceSpectrumInfo) {
    labels = {
      align: 'left',
      reserveSpace: true,
      useHTML: true,
      formatter() {
        return `
                <span class="aa-xlabel" style="width: 143px;">
                  <span class="aa-xlabel-title">${this.value}</span>
                  <span class="aa-xlabel-icon">${audienceSpectrumInfo[this.value].icon}</span>
                </span>
              `;
      },
    };
  }
  // eslint-disable-next-line no-undef
  Highcharts.chart(chartSelector, {
    chart: {
      type: 'bar',
      styledMode: true,
    },
    title: {
      text: translations.chart_title,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      format: translations.highcharts_tooltip_html,
    },
    xAxis: {
      categories,
      ...(labels && { labels }),
      title: {
        align: 'high',
        text: translations.x_axis_label,
        margin: 20,
      },
    },
    yAxis: {
      title: {
        align: 'low',
        text: translations.y_axis_label,
        margin: 20,
      },
    },
    series: [{
      name: translations.primary_period_label,
      data: primaryData,
    }, {
      name: translations.comparison_period_label,
      data: comparisonData,
    }],
    lang: {
      thousandsSep: ',',
    },
  });
}

$(document).ready(() => {
  $('*[data-graph-type="location-page-metric"]').each(function () {
    const metricName = $(this).data('metric-name');
    $(document).on(`${metricName}-metric-loaded`, () => {
      constructHighchartsTwoSeriesBarForMetric(metricName);
    });
  });
  $('*[data-graph-type="spectrum-page-metric"]').each(function () {
    const metricName = $(this).data('metric-name');
    $(document).on(`${metricName}-metric-loaded`, () => {
      constructHighchartsTwoSeriesBarForMetric(metricName);
    });
  });
});
